import '../Services/services.scss';
import tile1 from '../../assets/service3.jpeg';
import tile2 from '../../assets/tile2.jpeg';
import tile3 from '../../assets/service5.jpeg';
import tile4 from '../../assets/5.jpeg';
import tile5 from '../../assets/windscreen.jpeg';
import tile6 from '../../assets/stamp.jpeg';

export default function Services() {
    return (
        <>
            <div id='services' className='services-content'>
                <h2 style={{color: "#3f51b5", marginTop: 40, marginBottom: 40}}><b>Our Services</b></h2>
            </div>
            <div style={{display:"flex", justifyContent:"center"}}>
                <div className="services-container">
                    <p style={{fontSize: 18, textAlign: "center"}}>Thian Wah Motor offers complete and flexible solutions, specializing in servicing and repairing all truck makes. Our cost-effective services, delivered by highly trained technicians, ensure top-quality results, from minor repairs to fabrication and welding. We take pride in our work and reputation, continuously enhancing the skills, knowledge, professionalism, and teamwork of our technicians.</p>
                </div>
            </div>

            <div className='services-category-container'>
                <div style={{display:"flex", justifyContent:"center", marginBottom: 50}}>
                    <div className='row' style={{display:"flex", justifyContent:"space-around", width: "100%"}}>
                        <div className='col-lg-3 service-info-panel'>
                            <div>
                                <img alt="" className="service-info-img" src={tile1}/>
                            </div>
                            <h5 className='services-title'>Trailer Repair Service</h5>
                            <ul class="services-desc">
                                <li>Complete and flexible truck servicing and repairs for all makes</li>
                                <li>Highly trained technicians</li>
                                <li>Top-quality service - from minor repairs to welding and replacement</li>
                            </ul>
                        </div>

                        <div className='col-lg-3 service-info-panel'>
                            <div>
                                <img alt="" className="service-info-img" src={tile2}/>
                            </div>
                            <h5 className='services-title'>JOSAM Truck Frame Repair</h5>
                            <ul class="services-desc">
                                <li>Precise and cost-effective frame repairs</li>
                                <li>Ensuring accurate and high-quality repairs</li>
                            </ul>
                        </div>

                        <div className='col-lg-3 service-info-panel'>
                            <div>
                                <img alt="" className="service-info-img" src={tile3}/>
                            </div>
                            <h5 className='services-title'>JOSAM Induction Heater</h5>
                            <ul class="services-desc">
                                <li>Preventing frame distortion</li>
                                <li>Maintaining steel strength</li>
                                <li>Ensuring fast, safe, reliable, and lasting results</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div style={{display:"flex", justifyContent:"center", marginBottom: 50}}>
                    <div className='row' style={{display:"flex", justifyContent:"space-around", width: "100%"}}>
                        <div className='col-lg-3 service-info-panel'>
                            <div>
                                <img alt="" className="service-info-img" src={tile4}/>
                            </div>
                            <h5 className='services-title'>Accident Repairing</h5>
                            <ul class="services-desc">
                                <li>Repairing accident-damaged cars</li>
                                <li>Facilitates Puspakom Inspection</li>
                                <li>Ensure the highest quality of vehicle care</li>
                            </ul>
                        </div>

                        <div className='col-lg-3 service-info-panel'>
                            <div>
                                <img alt="" className="service-info-img" src={tile5}/>
                            </div>
                            <h5 className='services-title'>Windscreen Replacement</h5>
                            <ul class="services-desc">
                                <li>Windscreen claim for cars and lorries</li>
                            </ul>
                        </div>

                        <div className='col-lg-3 service-info-panel'>
                            <div>
                                <img alt="" className="service-info-img" src={tile6}/>
                            </div>
                            <h5 className='services-title'>Stamping Engine number & Chasis number</h5>
                            <ul class="services-desc">
                                <li>Stamp Engine & Stamp Chasis for cars and lorries</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}