import React from 'react';
import '../Carousel/carousel.scss';
import banner1 from '../../assets/banner/banner1.jpeg';
import banner2 from '../../assets/banner/banner2.jpeg';
import banner3 from '../../assets/banner/banner3.jpeg';
import banner4 from '../../assets/banner/banner4.jpeg';
import banner5 from '../../assets/banner/banner5.jpeg';
import banner6 from '../../assets/banner/banner6.jpeg';
import banner7 from '../../assets/banner/banner7.jpeg';
import banner8 from '../../assets/banner/banner8.jpeg';
import banner9 from '../../assets/banner/banner9.jpeg';
import banner10 from '../../assets/banner/banner10.jpeg'

export default function Carousel() {
    return (
        <section className='hero'>
            <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-bs-ride='carousel' >
                <ol className="carousel-indicators">
                    <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active"></li>
                    <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
                    <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></li>
                    <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"></li>
                    <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4"></li>
                    <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5"></li>
                    <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6"></li>
                    <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7"></li>
                    <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="8"></li>
                    <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="9"></li>
                </ol>
                <div className="carousel-inner">
                    <div className="carousel-item active" data-bs-interval="3000">
                        <img src={banner1} alt="banner 1" className="d-block w-100" />
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <img src={banner2} alt="banner 2" className="d-block w-100" />
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <img src={banner3} alt="banner 3" className="d-block w-100" />
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <img src={banner4} alt="banner 4" className="d-block w-100" />
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <img src={banner5} alt="banner 5" className="d-block w-100" />
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <img src={banner6} alt="banner 6" className="d-block w-100" />
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <img src={banner7} alt="banner 7" className="d-block w-100" />
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <img src={banner8} alt="banner 8" className="d-block w-100" />
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <img src={banner9} alt="banner 9" className="d-block w-100" />
                    </div>
                    <div className="carousel-item" data-bs-interval="3000">
                        <img src={banner10} alt="banner 10" className="d-block w-100" />
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </section>
    )
}