import Gallery from "./Gallery/Gallery";

export default function WhatWeDo2() {
    return (
        <div className='content-container bottom-yellow-background'>
            <div className='row landing-wrapper' style={{margin: "0px"}}>
                <div className='col-lg-6 separator'>
                    <div>
                        <h2><b><span style={{fontSize: 50, color: "#3f51b5"}}>100% </span><span style={{color: "#95831b"}}> Satisfaction Guarantee</span></b></h2>
                    </div>
                </div>
                <div className="row" style={{ display: "flex", justifyContent: "space-around", marginBottom: 50}}>
                    <div className="col-lg-3 guarantee-container">
                        <div className='fa fa-handshake-o guarantee-icon-container'></div>
                        <h4 style={{fontSize: 24, color: "#95831b"}}><b>Quality Support</b></h4>
                        <div>We offers top-tier assistance, ensuring vehicles consistently maintain optimal condition.</div>
                    </div>
                    <div className="col-lg-3 guarantee-container">
                        <div className='fa fa-truck guarantee-icon-container'></div>
                        <h4 style={{fontSize: 24, color: "#95831b"}}><b>All Commercial Vehicle Makes</b></h4>
                        <div>We cater to a wide range of commercial vehicle makes through our inclusive service offerings.</div>
                    </div>
                    <div className="col-lg-3 guarantee-container">
                        <div className='fa fa-cogs guarantee-icon-container'></div>
                        <h4 style={{fontSize: 24, color: "#95831b"}}><b>Variety Services</b></h4>
                        <div>We offer a diverse range of services to cater to the varied needs of our customers.</div>
                    </div>
                    <div className="col-lg-3 guarantee-container">
                        <div className='fa fa-clock-o guarantee-icon-container'></div>
                        <h4 style={{fontSize: 24, color: "#95831b"}}><b>Timely Delivery</b></h4>
                        <div>We provide prompt and efficient service to minimize downtime for clients and maintain smooth operations.</div>
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', paddingTop: 30, justifyContent: 'end', paddingRight: 60, margin:20}}>
                <div style={{fontSize: 32, paddingLeft: 70, color: "#3f51b5"}}>
                    <b>Project Gallery </b>
                </div>
            </div>
            <div className='hl' style={{marginLeft: 1250, marginRight: 60}}></div>

            <div style={{display: 'flex', paddingTop: 10, justifyContent: 'end', paddingRight: 60, margin: 20}}>
                <div style={{fontSize: 32, paddingLeft: 70, color: "#3f51b5"}}>
                    <div style={{fontSize: 18, color: "#ab982d", fontWeight: 600}}>New Tipper Bucket</div>
                </div>
            </div>
            <Gallery />
        </div>
    )
}