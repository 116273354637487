import { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import Gallery from "./Gallery/Gallery";

export default function Project() {
    const [counterOn, setCounterOn] = useState(false);
    
    return (
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            <div className="project-container project-container-background" style={{padding: 200}}>
                <div className="col-lg-6">
                    <div className="number-count-container">
                        {   
                            counterOn && 
                            <>
                                <div className="row">
                                    <div className="bi bi-buildings"><b><CountUp start={0} end={17011} duration={1} delay={0} /></b></div>
                                </div>
                                <div className="number-count-desc">
                                    Project Completed Last 5 years
                                </div>
                            </>
                        }
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="number-count-container" style={{textAlign: "left", paddingLeft: 60}}>
                        {   
                            counterOn && 
                            <>
                                <div className="row">
                                    <div className="bi bi-people-fill"><b><CountUp start={0} end={100} duration={1} delay={0} /></b></div>
                                </div>
                                <div className="number-count-desc">
                                    Great Experienced Team member
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </ScrollTrigger>
    )
}