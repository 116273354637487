import '../Footer/footer.scss';
import FacebookIcon from '../../assets/social_facebook.png';
import WhatsAppIcon from '../../assets/social_whatsapp.png';
import logo from "../../assets/logo.png";

export default function Footer() {
	return (
		<footer className="footer">
			<div className="container py-4" style={{paddingBottom: "0px"}}>
				<div className="row py-1">
					<div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
						<img src={logo} alt="Thian Wah Motor" width="150" className="mb-3" />
						<div className="social-section">
							<div className="text-left">
								<div className="box">
									<ul className="list-inline social-buttons">
										<li className="list-inline-item icons">
											<a href="https://www.facebook.com/thianwah1/" target="_blank" rel="noreferrer">
												<img src={FacebookIcon} alt="Thian Wah Motor Facebook" width="100%"/>
											</a>
										</li>	
										<li className="list-inline-item icons">
											<a href="https://api.whatsapp.com/send?phone=60126514861" target="_blank" rel="noreferrer">
												<img src={WhatsAppIcon} alt="Thian Wah Motor WhatsApp" width="100%"/>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-2 col-md-6 mb-4 mb-lg-0">
						<h6 className="text-uppercase font-weight-bold mb-4 footer-title-text">Our Group</h6>
						<ul className="list-unstyled mb-0">
							<li className="mb-2">
								<a href="/about/#aboutus" className="className footer-link footer-text">About Us</a>
							</li>
							<li className="mb-2">
								<a href="/services/#services" className="className footer-link footer-text">Services</a>
							</li>
						</ul>
					</div>
					<div className="col-lg-4 col-md-6 mb-lg-0">
						<div className='footer-padding-bottom' style={{display: "flex", alignItems: "column"}}>
							<div className="bi bi-geo-alt-fill className mb-4 footer-text"></div>
							<p className="footer-text" style={{marginTop: 8}}> No. 54 & 55, Jln Industri 3, Taman Perindustrian Lukut Indah 71010 Port Dickson, Negeri Sembilan.</p>
						</div>
						<div style={{display:"flex", justifyContent:"space-between"}}>
							<div className='footer-padding-bottom'>
								<div className="footer-text bi bi-telephone-fill" style={{color: "white"}} ><a className="footer-text footer-link" href="tel:+6066511689" >06-6511689</a></div>
								<p style={{marginLeft: "40px"}}><a className="footer-text footer-link" href="tel:+6066511841">06-6511841</a></p>
							</div>
							<div className='footer-padding-bottom'>
								<div className="bi bi-whatsapp className mb-4 footer-text" style={{color: "white"}}><a href="https://api.whatsapp.com/send?phone=60126514861" className="className mb-4 footer-text footer-link"> 012-6514861</a></div>
							</div>
						</div>
						<div className='footer-padding-bottom'>
							<div className="bi bi-envelope-fill className mb-4 footer-text" style={{color: "white"}}><a className="footer-link footer-text" href="mailto:thianwah1689@gmail.com">thianwah1689@gmail.com</a></div>
						</div>
						<div className='footer-padding-bottom'>
							<div className="bi bi-clock-fill className mb-4 footer-text">
							Mon - Sat:
								<div style={{marginLeft: "40px"}}>
									<p className="footer-text">9.00am - 5.30pm</p>
									<p className="footer-text">Sun / Public Holiday:</p>
									<p className="footer-text">Closed</p>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr className='container white-line' />
			<div className="container all-right-reserved py-3 d-flex justify-content-between">
				<div className="text-left p-1">
					<p className="className mb-0">TCopyright © 2023 Home, All Rights Reserved.</p>
				</div>
			</div>
			
		</footer>
	)
}