import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div style={{ 
        textAlign: 'justify', // Justifies the text
        padding: '50px', 
        maxWidth: '800px', 
        margin: '0 auto', 
        lineHeight: '1.6' 
      }}>
      <h1 style={{ textAlign: 'center' }}>Privacy Policy</h1>
      <p>
        Thianwah is committed to preserving your privacy. Please read the following privacy policy to understand how we use and protect the information obtained from those visiting and using Thianwah website <a href="https://Thianwah.com/" target="_blank" rel="noopener noreferrer">https://Thianwah.com/</a> (the "Website"). This policy is your guide to how we will handle information we learn about you from your visit to our Website.
      </p>
      <p>
        By using the Website you consent to the collection/retention and use of your personal information in accordance with this policy.
      </p>

      <h2>Information that we collect from you</h2>
      <ul style={{ listStyleType: 'disc', margin: '0', padding: '0', textAlign: 'left' }}>
        <li>We collect information about you and providing such information is strictly voluntary:</li>
        <li>When you provide us with your personal details (such as your name, contact details, e-mail address etc) by registering with us or submitting an enquiry via the Website;</li>
        <li>When you respond to surveys which we ask you to complete for research purposes;</li>
        <li>From your usage of the Website and any other information you post on the Website, e-mail or otherwise send to us.</li>
        <li>We collect and store only the following information about you: the name of the domain from which you access the Internet, the date and time you access our Website, and the Internet address of the website from which you linked to our Website.</li>
      </ul>

      <h2>Use of your information</h2>
      <ul style={{ listStyleType: 'disc', margin: '0', padding: '0', textAlign: 'left' }}>
        <li>To enable us to supply you with the goods, services and information which you have requested;</li>
        <li>To ensure that content from the Website is presented in the most effective manner for you and for your computer;</li>
        <li>To analyze the information we collect so that we can administer, support and improve and develop the Website;</li>
        <li>To provide you with information, products or services that you request from us or which we feel may interest you, where you have consented to be contacted for such purposes;</li>
        <li>To notify you about changes to our service.</li>
      </ul>

      <h2>Use of Links</h2>
      <p>
        Throughout our Web pages, we provide links to other servers which may contain information of interest to our readers. We take no responsibility for, and exercise no control over, the organizations, views, or accuracy of the information contained on other servers. Creating a text link from your website to our Website does not require permission. If you have a link you'd like us to consider adding to our Website, please send an email to <a href="mailto:admin@Thianwah.com">admin@Thianwah.com</a> with the subject "Link request."
      </p>

      <h2>Accessibility</h2>
      <p>
        This Website is designed to be accessible to visitors with disabilities and to comply with federal guidelines concerning accessibility. We welcome your comments. If you have suggestions on how to make the Website more accessible, please contact us at <a href="mailto:admin@Thianwah.com">admin@Thianwah.com</a>.
      </p>

      <h2>Kids and Privacy</h2>
      <p>
        For children who visit our Website, special rules apply. We do not request personal information about children, such as first and last name or street address and city. When kids send email to us, their online contact information (email address) is not used to re-contact them and is not maintained in retrievable form.
      </p>

      <h2>Storage of your information</h2>
      <p>
        Where we have given you (or where you have chosen) a password which enables you to access certain parts of the Website, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.
      </p>

      <h2>Disclosure of your information</h2>
      <p>
        Authorized personnel within Thianwah will be able to access the information you provide to us. We may also disclose your information to other third parties who act for us for the purposes set out in the policy or for purposes approved by you.
      </p>

      <h2>IP addresses and cookies</h2>
      <p>
        We may collect information about your computer, including where available your IP address, operating system, and browser type. This information is statistical data about users' browsing actions and patterns, does not identify any individual. It is used to inform improvements to the website, for system administration, and to report aggregate information to third parties.
      </p>

      <h2>Security</h2>
      <p>
        We employ security measures to protect your information from access by unauthorized persons and against unlawful processing, accidental loss, destruction or damage. Please help us keep our records updated by informing us of any changes to your email address and other contact details.
      </p>

      <h2>Questions about our policies</h2>
      <p>
        You have the right to ask for a copy of the information held by us in our records. If you have any questions about this privacy statement, the practices of this Website, or your dealings with this Website, you can contact us at: <a href="mailto:admin@Thianwah.com.my">admin@Thianwah.com.my</a>.
      </p>

      <h2>Governing law</h2>
      <p>
        These Terms and Conditions shall be governed and construed in accordance with the laws of Malaysia, whose courts shall have exclusive jurisdiction, although we retain the right to bring proceedings against you for breach of these conditions in your country of residence or other relevant country.
      </p>
    </div>
  );
}
