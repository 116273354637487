import '../VisionMission/visionmission.scss';

export default function VisionMission() {
    return (
        <div className='vm-container'>
            <div className='col-lg-4'>
                <h2 style={{color: "#3f51b5"}}><b>Vision</b></h2>
                <div style={{fontSize: 20}}>
                To be trusted and preferred workshop through the real experience in Malaysia.
                </div>
            </div>
            <div className='vl' style={{borderRadius: 10}}></div>
            <div className='col-lg-4'>
                <h2 style={{color: "#3f51b5"}}><b>Mission</b></h2>
                <div style={{fontSize: 20}}>
                To provide a consistent, dependable quality services meeting beyond customers’ expectation through timely, cost effectiveness and a lasting trusted relationship.
                </div>
            </div>
        </div>
    )
}