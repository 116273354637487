import VisionMission from "./VisionMission/VisionMission";

export default function About() {
    return (
        <div className="about-background">
            <div id="about" className='services-content'>
                    <h2 style={{color: "#3f51b5", marginTop: 40, marginBottom: 40}}><b>About Us</b></h2>
            </div>
            <div style={{display:"flex", justifyContent:"center"}}>
                <div className="about-us">
                    <h2 style={{marginBottom: 30, textAlign: "center"}}><b>ThianWah Motor Sdn Bhd - 38 years in the industry</b></h2>
                    <p style={{fontSize: 18, textAlign: "center"}}>ONE Contact Point, ONE Roof—ensuring swift truck turnaround for immediate recovery!</p>
                    <p style={{fontSize: 18, textAlign: "center"}}>To reduce your commercial fleet's downtime, we offer 24/7 emergency recovery services—just a phone call away.</p>
                    <p style={{fontSize: 18, textAlign: "center"}}>ThianWah Motor Sdn Bhd, a family-owned business for 38 years, is a well-established provider of reliable commercial repairs in Lukut, Port Dickson, Negeri Sembilan. Proud members of PIAM, we offer a comprehensive package, from recovery to repairs.</p>
                    <p style={{fontSize: 18, textAlign: "center"}}>Regardless of repair scale, our 100+ trained technicians prioritize quality and efficiency, rejecting touch-and-go practices to ensure safety, save costs, and build trust through our core values.</p>
                </div>
            </div>
            <VisionMission />
        </div>
       
    )
}