import { Link } from "react-router-dom";
import Carousel from "../Carousel/Carousel";
import '../Navbar/navbar.scss';
import logo from '../../assets/logo.png';

export default function Navbar() {
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light custom-navbar">
                <div className="row" style={{width: "100%", position: "relative", left: 12}}>
                    <div className="col-lg-4" style={{backgroundColor: "#3f51b5", paddingTop: 8, paddingBottom: 8, color: "white"}}>
                        # Leaders in Heavy Vehicle Insurance Claims and Repairs.
                    </div>
                    <div className="col-lg-8" style={{textAlign: "end", backgroundColor: "#92801d", paddingTop: 5, paddingBottom: 5, color: "white"}}>
                        <div className="row">
                            <div className="col-lg-9" style={{paddingRight: 30}}>
                                Monday - Saturday 9:00 AM - 5:30 PM 
                            </div>
                            <div className="col-lg-3">
                                Sunday/Public Holiday Closed
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid nav-container" style={{display: "flex", justifyContent:"space-between"}}>
                        <div className="col" style={{display: "flex", alignSelf: "center", paddingTop: 10, marginBottom: 10}}>
                            <Link className="navbar-brand" to="/">
                                <img src={logo} alt="Thian Wah Motor" width="60px" style={{marginLeft: 10}}/>
                            </Link>
                            <div style={{color:"#3f51b5", alignSelf: "center"}}>Thian Wah Motor Sdn. Bhd.</div>
                        </div>
                        <div style={{alignSelf: "center"}}>
                            <div className="collapse navbar-collapse">
                                <ul className="navbar-nav mb-2 mb-lg-0 ms-auto me-3">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" aria-current="page" to="/about/#about">About Us</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/services/#services">Services</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/contact/#contact">Contact</Link>
                                    </li>
                                    <li className="nav-item"> 
                                        <Link className="nav-link" to="/privacy-policy">Privacy Policy</Link> {/* New Privacy Policy Link */}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <Carousel />
        </div>
    );
}
