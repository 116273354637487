// src/page/Router.js
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Landing from "./Home/Landing";
import Services from "./Services/Services";
import About from "./About";
import ContactUs from "./ContactUs/ContactUs";
import Gallery from "./Gallery/Gallery";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy"; // Update import for Privacy Policy

export default function Router() {
    return (
        <ScrollToTop>
            <Routes>
                <Route path='/' element={<Landing />} />
                <Route path='/about' element={<About />} />
                <Route path='/services' element={<Services />} />
                <Route path='/contact' element={<ContactUs />} />
                <Route path='/gallery' element={<Gallery />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} /> {/* New Privacy Policy Route */}
            </Routes>
        </ScrollToTop>
    );
}
