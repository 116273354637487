import Image from '../assets/ThianWahMotor.jpeg'

export default function Introduction() {
    return (
        <div className='panel-background'>
            <div className='container content-container'>
                <div className='row landing-wrapper'>
                    <div className='col-lg-6'>
                        <img src={Image} alt="" style={{width: "100%"}}></img>
                    </div>
                    <div className='col-lg-6 separator'>
                        <div className='row'>
                            <div style={{color:"#3f51b5", fontSize: 16, paddingBottom: 10}}>
                            MORE THAN <span style={{fontSize: 30}}><b>38</b></span> YEARS EXPERIENCE
                            </div>
                            <h2 style={{color:"#3f51b5", marginBottom: 30}}><b>17,011 Successful Projects in Heavy Vehicle Repairs</b></h2>
                            <p style={{textAlign: "justify"}}>Thian Wah Motor boasts a remarkable track record, completing 17,011 projects in the last 5 years. With a dedicated team of over 100 highly experienced members, we continue to excel in heavy vehicle accident automotive claims and comprehensive accident repair services.</p>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    )
}