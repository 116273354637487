import './branch_info.scss';
import branchImage1 from '../../../assets/tw_hq.png';
import branchImage2 from '../../../assets/tw_lukut.png';
import branchImage3 from '../../../assets/tw_lukut_hino.png';
import branchImage4 from '../../../assets/tw_shahalam.png';
import mercedesLogo from '../../../assets/mercedes_logo.jpeg';
import fusoLogo from '../../../assets/fuso_logo.png';
import hinoLogo from '../../../assets/hino_logo.jpg';

const BranchInfo = () => {
    return (
        <div className="branch-container">
            {/* Head Office Section */}
            <div className="branch-section head-office row">

                <div className="branch-header d-md-none" style={{ marginBottom: "30px" }}>
                    <h3>Head Office / Ibu Pejabat</h3>
                </div>
                <div className="branch-image col-md-4 col-12">
                    <img
                        src={branchImage1}
                        alt="Head Office"
                        className=""
                    />
                </div>
                <div className="branch-details col-md-8 col-12">
                    <div className="branch-header d-none d-md-block">
                        <h3>Head Office / Ibu Pejabat</h3>
                    </div>
                    <div className='spacer'> </div>
                    <div>
                        <h2>THIAN WAH MOTOR SDN BHD</h2>
                        <p><div>No.119 & Tingkat 1,</div>
                            <div>Taman Perindustrian Lukut Indah,</div>
                            <div>71010 Lukut, Port Dickson,</div> <div>Negeri Sembilan</div></p>
                        <p>Tel: 06-651 1689 / 06-651 1841</p>
                        <p>Whatsapp: <a href='https://api.whatsapp.com/send?phone=60126514861'>012-6514861</a></p>
                        <p>Email: <a href="mailto:claims@thianwah.com.my">claims@thianwah.com.my</a></p>
                    </div>
                </div>
            </div>


            {/* Branches Section */}

            <div className="branch-section head-office row">

                <div className="branch-header d-md-none" style={{ marginBottom: "30px" }}>
                    <h3>
                        <span>Lukut Branch – </span>
                        <span className="branch-icons">
                            <img src={mercedesLogo} alt="Mercedes logo" />
                            <img src={fusoLogo} alt="Fuso logo" />
                        </span></h3>
                </div>
                <div className="branch-image col-md-4 col-12">
                    <img
                        src={branchImage2}
                        alt="Lukut Branch"
                        className=""
                    />
                </div>
                <div className="branch-details col-md-8 col-12">
                    <div className="branch-header d-none d-md-block">
                        <h3>
                            <span>Lukut Branch – </span>
                            <span className="branch-icons">
                                <img src={mercedesLogo} alt="Mercedes logo" />
                                <img src={fusoLogo} alt="Fuso logo" />
                            </span></h3>
                    </div>
                    <div className='spacer'> </div>
                    <div>
                        <h4>THIAN WAH TRUCK ENGINEERING SDN BHD</h4>
                        <p>(Authorised Dealer of Daimler Trucks)</p>
                        <p>Email: <a href="mailto:thianwahtruck@gmail.com">thianwahtruck@gmail.com</a></p>
                        <p><div>LOT 1247, BUKIT PALONG, 71010 PORT DICKSON,</div>
                            <div> NEGERI SEMBILAN</div></p>
                    </div>
                </div>
            </div>


            <div className="branch-section head-office row">

                <div className="branch-header d-md-none" style={{ marginBottom: "30px" }}>
                    <h3>
                        <span>Lukut Branch - </span>
                        <span className="branch-icons">
                            <img src={hinoLogo} alt="Hino logo" />
                        </span>
                    </h3>
                </div>
                <div className="branch-image col-md-4 col-12">
                    <img
                        src={branchImage3}
                        alt="Lukut Branch"
                        className=""
                    />
                </div>
                <div className="branch-details col-md-8 col-12">
                    <div className="branch-header d-none d-md-block">
                        <h3>
                            <span>Lukut Branch - </span>
                            <span className="branch-icons">
                                <img src={hinoLogo} alt="Hino logo" />
                            </span>
                        </h3>
                    </div>
                    <div className='spacer'> </div>
                    <div>
                        <h4>THIAN WAH TRUCK SDN BHD</h4>
                        <p>(HINO APPROVED SERVICE OUTLET)</p>
                        <p>Tel: 010-9339913</p>
                        <p>Email: <a href="mailto:thianwah.hino@gmail.com">thianwah.hino@gmail.com</a></p>
                        <p><div>LOT 1247, BUKIT PALONG, 71010 PORT DICKSON,</div> <div>NEGERI SEMBILAN</div></p>
                    </div>
                </div>
            </div>


            <div className="branch-section head-office row">

                <div className="branch-header d-md-none" style={{ marginBottom: "30px" }}>
                    <h3>
                        <span>(OPENING SOON) Shah Alam Branch </span></h3>
                </div>
                <div className="branch-image col-md-4 col-12">
                    <img
                        src={branchImage4}
                        alt="Shah Alaam Branch"
                        className=""
                    />
                </div>
                <div className="branch-details col-md-8 col-12">
                    <div className="branch-header d-none d-md-block">
                        <h3>
                            <span>(OPENING SOON) Shah Alam Branch </span></h3>
                    </div>
                    <div className='spacer'> </div>
                    <div>
                        <h4>THIAN WAH MOTOR (SHAH ALAM) SDN BHD</h4>
                        <p><div>NO.6 (PT36383) JALAN TIANG U8/93, </div>
                            <div>BUKIT JELUTONG, </div>
                            <div>SEKSYEN U8 40150 SHAH ALAM, </div>
                            <div>SELANGOR</div> </p>
                    </div>
                </div>
            </div>
            {/* <div className="branches row">
                <div className="col-md-6 col-12">
                    <div className="branch-section">
                        <div className="branch-header" style={{ marginBottom: "30px" }}>
                            <span>Lukut Branch – </span>
                            <span className="branch-icons">
                                <img src={mercedesLogo} alt="Mercedes logo" />
                                <img src={fusoLogo} alt="Fuso logo" />
                            </span>
                        </div>

                        <div className="branch-content">
                            <div className="branch-image">
                                <img
                                    src={branchImage2}
                                    alt="Lukut Branch"
                                    className=""
                                />
                            </div>
                            <div className="branch-details">
                                <h4>THIAN WAH TRUCK ENGINEERING SDN BHD</h4>
                                <p>(Authorised Dealer of Daimler Trucks)</p>
                                <p>Email: thianwahtruck@gmail.com</p>
                                <p><div>LOT 1247, BUKIT PALONG, 71010 PORT DICKSON,</div>
                                    <div> NEGERI SEMBILAN</div></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-12">
                    <div className="branch-section">
                        <div className="branch-header" style={{ marginBottom: "30px" }}>
                            <span>Lukut Branch - </span>
                            <span className="branch-icons">
                                <img src={hinoLogo} alt="Hino logo" />
                            </span>
                        </div>
                        <div className="branch-content">
                            <div className="branch-image">
                                <img
                                    src={branchImage3}
                                    alt="Lukut Branch"
                                    className=""
                                />
                            </div>
                            <div className="branch-details">
                                <h4>THIAN WAH TRUCK SDN BHD</h4>
                                <p>(HINO APPROVED SERVICE OUTLET)</p>
                                <p>Tel: 010-9339913</p>
                                <p>Email: thianwah.hino@gmail.com</p>
                                <p><div>LOT 1247, BUKIT PALONG, 71010 PORT DICKSON,</div> <div>NEGERI SEMBILAN</div></p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-md-6 col-12">
                    <div className="branch-section">
                        <div className="branch-header" style={{ marginBottom: "30px" }}>
                            <span>(OPENING SOON) Shah Alam Branch </span>
                        </div>
                        <div className="branch-content">
                            <div className="branch-image">
                                <img
                                    src={branchImage4}
                                    alt="Shah Alam Branch"
                                    className=""
                                />
                            </div>
                            <div className="branch-details">
                                <h4>THIAN WAH MOTOR (SHAH ALAM) SDN BHD</h4>
                                <p><div>NO.6 (PT36383) JALAN TIANG U8/93, </div>
                                    <div>BUKIT JELUTONG, </div>
                                    <div>SEKSYEN U8 40150 SHAH ALAM, </div>
                                    <div>SELANGOR</div> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BranchInfo;
